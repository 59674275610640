import React from 'react'
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from 'react-bootstrap/Spinner';
import { useEffect } from 'react';

const Main = () => {
    const [showquestion, setShowquestion] = useState(false)
    const [inputValue, setInputValue] = useState("");
    const [questions, setQuestionlist] = useState([])
    const [loader, setLoader] = useState(false)
    const firebaseUrl = localStorage.getItem('dynaminid');

    const redirectUser = () => {
        window.location.href = `https://triviamaker.com/register/?response=${firebaseUrl}`;
    }
    useEffect(() => {
    }, []);
    const generateDynamicId = () => {
        const idLength = 10;
        let id = '';
        for (let i = 0; i < idLength; i++) {
            const randomNumber = Math.floor(Math.random() * 10);
            id += randomNumber.toString();
        }
        return id;
    };

    const generateQuestion = () => {
        setLoader(true)
        fetch('https://play.triviamaker.com/questionGenerator.php?topic=' + inputValue)
            .then((response) => response.json())
            .then(data => {
                setQuestionlist(data.questions)
                setShowquestion(true)
                setLoader(false)
                const staticId = generateDynamicId();
                const firebaseUrl = `https://trivia-maker-aicontents.firebaseio.com/response/${staticId}.json`;
                localStorage.setItem("dynaminid", staticId);
                data.Topic = inputValue
                fetch(firebaseUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(savedData => {
                    })
                    .catch(error => {
                        console.error('Error saving data to Firebase:', error);
                    });
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const inputChange = (e) => {
        setInputValue(e.target.value)
    }

    return (
        <div id='questionsection' style={{ height: "90%", overflowY: `${showquestion ? "auto" : ""}` }} className='container-fluid d-flex align-items-center justify-content-center'>
            {loader &&
                <div className='loader' style={{zIndex:"999"}}>
                    <Spinner  animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
            <div style={{ maxWidth: '800px', margin: 'auto' }}>
                <div className='row'>
                    <div className='col-md-9 col-lg-9' style={{ color: 'white' }}>
                        <div style={{ width: '90%', textAlign: 'start', marginLeft: 'auto' }} className=' d-flex flex-column align-items-center justify-content-end h-100 pb-4'>
                            <h2 className='m-0 w-100'>Need some trivia questions?</h2>
                            <p style={{ fontSize: '18px' }} className='m-0 pt-3 w-100'>Type in a subject and let the Triviamaker bust them out!</p>
                        </div>
                    </div>
                    <div className='col-md-3 col-lg-3'>
                        <img style={{ width: '200px' }} alt='' src='https://triviamaker.com/AI%20Generator%20Robot.gif' />
                    </div>
                </div>
                <div className='form-control ' style={{ padding: '1rem', backgroundColor: '#e0e0e0', borderRadius: '0px', }}>
                    <div className='d-flex mb-3'>
                        <Form.Control
                            placeholder='Enter topic name (eg: geography)'
                            className='me-3 ai-inputbox'
                            type="text"
                            value={inputValue}
                            onChange={inputChange}
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                        <Button disabled={!inputValue} onClick={generateQuestion} className={inputValue ? "enalbeclass" : "buttonstyle"} variant="primary">Generate</Button>{' '}
                    </div>
                    {showquestion && <Accordion defaultActiveKey="0">
                        {/* {questionlist.map((getlistdata) => { */}
                        {questions.slice(0, 3).map((getlistdata, index) => {
                            return (
                                <>
                                    <Accordion.Item key={index + 1}  eventKey={index + 1}  >
                                        <Accordion.Header>
                                            <h5>{getlistdata.question}</h5>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul className='questionclass' >
                                                <li>{getlistdata.optionA}</li>
                                                <li>{getlistdata.optionB}</li>
                                                <li>{getlistdata.optionC}</li>
                                                <li>{getlistdata.optionD}</li>
                                            </ul>
                                            <div className='answer'>Correct Answer : {getlistdata.correctAnswer}</div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </>
                            )
                        })}
                    </Accordion>}

                </div>
                <div className='mt-3 text-center' >
                    {/* <NavLink target="_blank" href={`https://triviamaker.com/register/${firebaseUrl}`}> */}
                    <Button disabled={!showquestion} onClick={redirectUser} className={showquestion ? "createenable py-2 px-4 buttonstyle" : "createdisable py-2 px-4 buttonstyle"} style={{ width: 'unset', height: 'unset', boxShadow: "none", fontSize: '1.5rem' }} variant="primary">
                        Create Game with these Questions
                    </Button>
                    {/* </NavLink> */}
                </div>
            </div>
        </div>
    )
}

export default Main
