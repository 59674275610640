import React from 'react'
import Main from './pages/Main'
import Header from './pages/Header'

const App = () => {
  return (
    <div className='h-100' >
      <Header/>
      < Main  />
    </div>
  )
}

export default App
