import React from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ListGroup from 'react-bootstrap/ListGroup';


const Header = () => {
    const navArray = [
        {
            linkdata: 'Tutorials',
            linkURL: 'https://triviamaker.com/tutorials/',
        },
        {
            linkdata: 'FAQ',
            linkURL: 'https://triviamaker.com/faq/',
        },
        {
            linkdata: 'Support',
            linkURL: 'https://triviamaker.ticksy.com/',
        }
    ]

    const listarrayAccount = [
        {
            id: "1",
            linkdata: 'Premium Account Overview',
            navitemsURL: 'https://triviamaker.com/pricing/'
        },
        {
            id: "2",
            linkdata: 'Privacy Policy',
            navitemsURL: 'https://triviamaker.com/privacypolicy/'
        },
        {
            id: "3",
            linkdata: 'Terms of Use',
            navitemsURL: 'https://triviamaker.com/terms-of-use/'
        },
        {
            id: "4",
            linkdata: 'Login',
            navitemsURL: 'https://triviamaker.com/login/'
        },
        {
            id: "5",
            linkdata: 'Create Account',
            navitemsURL: 'https://triviamaker.com/register/'
        }
    ]
    return (
        <div className=' bg-main' id='ai-header' >
            <Navbar expand="lg" className="container">
                <Container fluid className='bg-main'>
                    <Navbar.Brand >
                        <img className="logo" src="https://triviamaker.com/wp-content/uploads/2018/11/TriviaMaker-Web-Header-Logo.png" alt="TriviaMaker – Quiz Creator" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="ms-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px', columnGap: "20px" }}
                            navbarScroll
                        >
                            <Nav.Link className='border-animate' href="https://triviamaker.com/dashboard">Launch TriviaMaker</Nav.Link>
                            <Nav.Link className='border-animate' href="https://triviamaker.com/pricing/">Pricing</Nav.Link>
                            <div className='help position-relative'>
                                <Nav.Link className='border-animate' href="https://triviamaker.com/dashboard/#">Help</Nav.Link>
                                <ListGroup className='helpdetails'>
                                    {navArray.map((navlink) => {
                                        return (
                                            <>
                                                <ListGroup.Item className='border-0 '>
                                                    <Nav.Link className='p-0' href={navlink.linkURL}>{navlink.linkdata}</Nav.Link>
                                                </ListGroup.Item>
                                            </>
                                        )
                                    })
                                    }

                                </ListGroup>
                            </div>
                            <div className='manageaccount' >
                                <Nav.Link className='border-animate' href="https://triviamaker.com/account/">Manage Account</Nav.Link>
                                <ListGroup className='managedetails'>
                                    {
                                        listarrayAccount.map((navitem ,id) => {
                                            // console.log(navitem.id)
                                            return (
                                                <>
                                                    <ListGroup.Item key={navitem.id}>
                                                        <Nav.Link className='p-0' href={navitem.navitemsURL}>{navitem.linkdata}</Nav.Link>
                                                    </ListGroup.Item>
                                                </>
                                            )
                                        })
                                    }
                                </ListGroup>
                            </div>
                            {/* <Nav.Link href="#" disabled>
                                Link
                            </Nav.Link> */}
                        </Nav>
                        <Form className="d-flex">
                            {/* <Form.Control
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                            /> */}
                            {/* <Button variant="outline-success">Search</Button> */}
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header
